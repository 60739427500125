import React, { useEffect, useRef, useState } from "react";
import Gif from "assets/images/register/animation.gif";
import VolumeX from "assets/images/register/volume-x.svg";
import Speaker from "assets/images/register/speaker.png";
import JessicaImageCard from "./JessicaImage";
import AudioFile from "assets/audios/register/Voice-over-signup.mp3";
import icon1 from "assets/images/others/demo1.svg";
import icon2 from "assets/images/others/demo2.svg";
import icon3 from "assets/images/others/demo3.svg";

const messages = [
  {
    title: "5-Min Call",
    content: "Turn your thoughts into polished content in just 5 minutes.",
    icon: icon1,
  },
  {
    title: "Full Week of Content",
    content: "Ready-to-publish posts for LinkedIn, Quora, and podcasts.",
    icon: icon2,
  },
  {
    title: "Just Talk",
    content: "Speak naturally—Jessica will transform it into engaging content.",
    icon: icon3,
  },
];

const AnimationCard = ({ ...props }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isWatch, setIsWatch] = useState(false);
  const audioRef = useRef(new Audio(AudioFile));

  const handleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const endAudio = () => {
      setIsPlaying(false);
    };
    const audio = audioRef.current;
    audio.addEventListener("ended", endAudio);
    return () => {
      audio.removeEventListener("ended", endAudio);
    };
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    return () => {
      if (!audio.paused) {
        audio.pause();
      }
      setIsWatch(false);
    };
  }, []);

  return (
    <div className={`w-full flex items-stretch mb-10 ${props.className}`}>
      <div className="h-full flex-1 rounded-[30px] lg:rounded-[45.497px] bg-off-white-super-light md:p-2 lg:p-5 flex flex-col justify-around gap-2 md:gap-1 relative">
        <div className="text-black font-figtree text-[16px] sm:text-[22px] font-[600] leading-[130%] pl-3 hidden md:flex gap-1 items-baseline">
          <span>What can you expect?</span>
          {isWatch && <span className="text-[12px]">(video demo)</span>}
        </div>
        <div className="relative md:pl-2 md:pb-2 lg:pl-3 lg:pb-3 md:flex-none flex-1 flex">
          <img
            src={Gif}
            alt="Animation"
            className="rounded-[28px] md:rounded-[40px] border border-[#D7CCC6] md:h-auto"
            style={{ visibility: isWatch ? "visible" : "hidden" }}
          />
          <div
            className="flex w-[38px] h-[38px] p-1 justify-center items-center rounded-full border border-solid border-off-white-dark absolute left-2 bottom-2 md:left-0 md:bottom-0 bg-off-white-base link interact"
            onClick={handleAudio}
            style={{ visibility: isWatch ? "visible" : "hidden" }}
          >
            <img
              src={isPlaying ? Speaker : VolumeX}
              alt="volume-x"
              className="w-[18px] h-[18px]"
            />
          </div>
          {isWatch || (
            <div className="absolute w-full h-full flex flex-col justify-around md:justify-center gap-1 md:gap-6 items-center px-4 md:px-0 overflow-auto">
              {messages.map((m, index) => (
                <div className="w-full gap-4 flex items-start" key={index}>
                  <img
                    src={m.icon}
                    alt="icon"
                    className="flex-shrink-0 size-6 sm:size-10 max-400:hidden"
                  />
                  <div className="flex-1 text-text-primary font-figtree text-[14px] leading-[143%] tracking-[0.13px] flex flex-col">
                    <strong>{m.title}</strong>
                    <span>{m.content}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div
          className="absolute -bottom-10 left-1/2 -translate-x-1/2 font-figtree text-[18.9px] font-[600] leading-[143%] tracking-[0.176px] interact text-primary-main link text-center text-nowrap"
          onClick={() => setIsWatch(true)}
        >
          🎥 Watch a demo call
        </div>
      </div>
      <JessicaImageCard/>
    </div>
  );
};

export default AnimationCard;
