import React, { useCallback, useEffect, useRef, useState } from "react";
import Jessica from "assets/images/jessica.png";

const JessicaImageCard = () => {
  const divRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const idRef = useRef(Math.random().toString());

  const handleResize = useCallback(() => {
    if (divRef.current) {
      setDimensions({
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(handleResize);

    const div = divRef.current;
    if (div) {
      observer.observe(div);
    }

    return () => {
      if (div) {
        observer.unobserve(div);
      }
      observer.disconnect();
    };
  }, [handleResize]);

  useEffect(() => {
    // Function to update the dimensions state when the parent div size changes

    // Initial size calculation
    handleResize();

    // Add event listener for window resize to adjust SVG size
    window.addEventListener("resize", handleResize);
    window.addEventListener("DOMContentLoaded", handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("DOMContentLoaded", handleResize);
    };
  }, [handleResize]);

  const unit = dimensions.width / 4;
  const height = dimensions.height;

  const clipPath = `M${
    unit * 2
  },20 A${unit},${unit} 0 0 0 ${unit},0 L0,0 L0,${height} L${unit},${height} A${unit},${unit} 0 0 0 ${
    unit * 2
  },${height - 20} A${unit},${unit} 0 0 0 ${unit * 3},${height} L${
    unit * 4
  },${height} L${unit * 4},0 L${unit * 3},0 A${unit},${unit} 0 0 0 ${
    unit * 2
  },20`;

  return (
    <div className="relative h-full hidden sm:block" ref={divRef}>
      {/* Embedded SVG */}
      <svg
        width={dimensions.width}
        height={dimensions.height}
        className="absolute"
        style={{ top: 0, left: 0 }}
      >
        {/* Define Clip Path */}
        <defs>
          <clipPath id={`clipShape${idRef.current}`}>
            <path d={clipPath} />
          </clipPath>
        </defs>
      </svg>

      {/* Main Content */}
      <div
        className="h-full bg-off-white-super-light flex flex-col justify-end rounded-[30px] lg:rounded-[45.497px] overflow-hidden w-[30vw] max-w-[160px] min-w-[100px] md:max-w-none md:w-[160px] xl:w-[190px]"
        style={{
          clipPath: `url(#clipShape${idRef.current})`,
          WebkitClipPath: `url(#clipShape${idRef.current})`,
        }}
      >
        <img
          src={Jessica}
          alt="jessica"
          className="w-[30vw] max-w-[160px] min-w-[100px] md:max-w-none md:w-[160px] xl:w-[190px] h-auto"
        />
      </div>
    </div>
  );
};

export default JessicaImageCard;
